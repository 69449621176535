import { Injectable, Component, OnInit, ViewChild } from "@angular/core";

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;
  constructor(private toastr: ToastrService) { }
  ngOnInit() {
    this.toastr.overlayContainer = this.toastContainer;
  }
  showSuccess(message: string, title: string) {
    this.toastr.success(message, title);
  }

  showError(message: string, title: string) {
    this.toastr.error(message, title);
  }

  showInfo(message: string, title: string, timing = 1800) {
    this.toastr.clear()
    this.toastr.info(message, '', {
      timeOut: timing,
      positionClass: 'toast-center'
    });
  }

  showWarning(message: string, title: string) {
    this.toastr.warning(message, title);
  }
}
