import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';
import { AuthLoginV2Component } from './auth-login-v2/auth-login-v2.component';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from 'app/shared/shared.module';
import { AuthForgotPasswordV2Component } from './auth-forgot-password-v2/auth-forgot-password-v2.component';


// routing
const routes: Routes = [
  {
    path: 'login',
    component: AuthLoginV2Component,
    data: { animation: 'auth' }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: { animation: 'auth' }
  },
  {
    path: "forgot-password",
    component: AuthForgotPasswordV2Component,
  },
];

@NgModule({
  declarations: [AuthLoginV2Component,RegisterComponent,AuthForgotPasswordV2Component],
  imports: [CommonModule, RouterModule.forChild(routes), NgbModule, FormsModule, ReactiveFormsModule, CoreCommonModule,SharedModule]
})
export class AuthenticationModule {}
