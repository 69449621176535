import { Directive, ElementRef, Renderer2, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[dynamicInputWidth]'
})
export class DynamicInputWidthDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.adjustInputWidth();
  }

  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement) {
    this.initAdjustInputWidth(input);
  }

  adjustInputWidth() {
    const input = this.el.nativeElement;
    const textWidth = this.getTextWidth(input.value);
    this.renderer.setStyle(input, 'width', (textWidth*2.1) + 'px');
  }

  initAdjustInputWidth(input: HTMLInputElement) {
    const textWidth = this.getTextWidth(input.value);
    this.renderer.setStyle(input, 'width', (textWidth*2.1) + 'px');
  }

  getTextWidth(text: string): number {
    const span = document.createElement('span');
    span.textContent = text;
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    document.body.appendChild(span);
    const width = span.offsetWidth;
    document.body.removeChild(span);
    return width;
  }
}
