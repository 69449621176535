import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { CoreCommonModule } from "@core/common.module";

import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { HomeComponent } from "./home.component";
import { SharedModule } from "app/shared/shared.module";
import { AuthGuard } from "app/auth/helpers";
import { MySafeHtmlPipe } from './my-safe-html.pipe';

const routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [HomeComponent, MySafeHtmlPipe],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    SharedModule,
  ],
  exports: [HomeComponent],
})
export class SampleModule {}
