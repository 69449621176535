<div (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()">
    <ng-container *ngIf="!editing; else inputTemplate">
      <span
        class="editable-span"
        [class.hovered]="hovered"
        (click)="startEditing()"
      >
        {{ initialText }}
      </span>
    </ng-container>
    <ng-template #inputTemplate>
      <input
        class="editable-input"
        [(ngModel)]="editedText"
        (blur)="finishEditing()"
      />
    </ng-template>
  </div>