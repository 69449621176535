import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { MiscellaneousModule } from "./main/miscellaneous/miscellaneous.module";
import { AuthenticationModule } from "./main/authentication/authentication.module";
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from "./auth/helpers";
import { HomeComponent } from "./main/sample/home.component";

const appRoutes: Routes = [
  {
    path: "question",
    loadChildren: () =>
      import("./main/questions/questions.module").then(
        (m) => m.QuestionsModule
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: "rule",
    loadChildren: () =>
      import("./main/rule/rule.module").then((m) => m.RuleModule),
    canActivate: [AuthGuard],
  },
  {
    path: "game",
    loadChildren: () =>
      import("./main/game/game.module").then((m) => m.GameModule),
  },
  {
    path: "chat",
    loadChildren: () =>
      import("./main/chat/chat.module").then((m) => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: "lessons",
    loadChildren: () =>
      import("./main/lessons/lessons.module").then((m) => m.LessonsModule),
  },
  {
    path: "level-test",
    loadChildren: () =>
      import("./main/test-level/test-level.module").then(
        (m) => m.TestLevelModule
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./main/sample/sample.module").then((m) => m.SampleModule),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    children: [{ path: "", redirectTo: "/home", pathMatch: "full" }],
  },
  {
    path: "**",
    redirectTo: "/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    AuthenticationModule,
    MiscellaneousModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
