<div class="content-wrapper container-xxl p-md-3 mt-md-0">
  <div class="content-body">
    <!-- Basic Alerts start -->
    <section>
      <core-loading-screen *ngIf="!userState">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </core-loading-screen>
      <div *ngIf="!!userState" class="row">
        <div class="col-12 d-md-none d-flex justify-content-center align-items-center">
          <img *ngIf="!!levelImg" [src]="levelImg" alt="step" width="90%" class="mb-3 levelLoad" />
        </div>
        <div class="col-sm-12 col-lg-7">
          <div class="card" *ngIf="(userState?.type != 'PENDING') && open && !hasAttempt" [ngClass]="{
              'card-blue': userState?.type == 'SESSION',
              'test-card': userState?.type === 'TEST'
            }">
            <div class="card-header">
              <img src="./../../../assets/images/icons/svg-images/home-config.svg" alt="config-icon" />
            </div>
            <div class="card-body pb-1 row">
              <div class="col-lg-12 pt-1">
                <h4 style="font-size: 1.375em; font-family: 'Poppins', sans-serif; font-weight: bold;color:#000;" *ngIf="userState?.type == 'SESSION'">
                  Votre session est prête !
                </h4>
                <h4 style="font-size: 1.375em; font-family: 'Poppins', sans-serif; font-weight: bold;color:#000;" *ngIf="userState?.type == 'TEST' && userState?.level > 1">
                  Tu as fini toutes les sessions de ce palier
                </h4>
              </div>
              <div class="col-lg-12 pt-1">
                <h1 *ngIf="userState?.type == 'SESSION'">
                  <b style="font-size: 2.25; font-family: 'Poppins', sans-serif; font-weight: bold;">Démarrer la session du jour</b>
                </h1>
                <h1 *ngIf="userState?.type == 'TEST'">
                  <b style="font-size: 2.25; font-family: 'Poppins', sans-serif; font-weight: bold;">Un nouveau test de niveau est disponible</b>
                </h1>
              </div>
              <div class="col-lg-12 pb-1 pt-1">
                <h4>Durée estimée : 12 minutes</h4>
              </div>
              <div class="col-lg-12 pb-1 pt-1">
                <button class="jecommence" pButton type="button" [ngClass]="{
                    'orange-button': userState?.type == 'SESSION',
                    'black-button': userState?.type == 'TEST'
                  }" label="Je commence" (click)="goToNextStep()"></button>
              </div>
            </div>
          </div>
          <div *ngIf="hasAttempt" class="card-body pb-1 row locked-card pb-3">
            <div class="card-header">
              <img src="./../../../assets/images/svg/lock.svg" alt="config-icon" />
            </div>
            <div class="card-body pb-1 row">
              <div class="col-lg-12 pt-1">
                Vous avez déjà effectué votre session du jour.
              </div>
              <div class="col-lg-12 pt-1">
                <b>Votre prochaine session du jour sera prête à partir de demain.</b>
              </div>
              <div class="col-lg-12 pb-0 pt-3">
                <button pButton type="button" class="black-button" label="Je recommence" (click)="resetSession(lastSession)"></button>
              </div>
              <!-- <div class="col-lg-12 pb-1 pt-1">
                <h4>Get in touch with us</h4>
              </div> -->
            </div>
          </div>
          <div *ngIf="!open" class="card-body pb-1 row locked-card">
            <div class="card-header">
              <img src="./../../../assets/images/svg/lock.svg" alt="config-icon" />
            </div>
            <div class="card-body pb-1 row">
              <div class="col-lg-12 pt-1">
                Votre période d'essai est terminée.
              </div>
              <div class="col-lg-12 pt-1">
                <b>Merci de prendre un abonnement afin de continuer ou de nous contacter.</b>
              </div>
              <!-- <div class="col-lg-12 pb-1 pt-1">
                <h4>Get in touch with us</h4>
              </div> -->
            </div>
          </div>
          <!--TODO: add Pending -->
          <div class="card" *ngIf="userState?.type == 'PENDING'">
            <img src="./../../../assets/images/svg/loading2.svg" alt="" />
          </div>
          <div class="card" *ngIf="userState?.type == 'SESSION'">
            <div class="card-header p-1">
              <span class="card-title" style="font-weight: bold; font-size: 1.25em; color: black">
                Sessions effectuées
              </span>
            </div>
            <div class="card-body mr-1 ml-1 row" *ngIf="!!userState?.sessionsHistory">
              <div class="col-12 session-style" *ngFor="let session of userState?.sessionsHistory">
                <div>
                  <div style="font-size: 0.625em; color: black">
                    Effectuée le
                    {{ session.date | date : "medium" : "" : "en-US" }}
                  </div>
                  <div style="font-size: 1.875em; font-weight: bold; color: black">
                    Session n°{{ session.sessionNumber }}
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <img (click)="resetSession(session.sessionId)" style="cursor: pointer;" src="./../../../assets/images/icons/home-icons/play.svg" alt="play-icon" height="40px"
                    width="40px" />
                  <div class="col-auto pourcentage-item">
                    <span style="font-size: 1.1875em; font-weight: bold; color: black">
                      {{ session.score }}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--info section-->
        <div class="col-sm-12 col-lg-5">
          <div class="card mb-1 position-relative" style="margin-bottom: 0">
            <span style="position: absolute; right: -26px; top: -26px">
              <img src="./../../../assets/images/icons/svg-images/home-lamp.svg" alt="lamp-icon" width="52px"
                height="52px" />
            </span>
            <div class="card-header">
              <span class="card-title" style="font-family: 'Poppins', sans-serif;font-size: 1.625em; font-weight: bold; color: black">L'info du jour</span>
            </div>
            <div class="card-body pb-1">
              <p class="card-text" style="font-size: 1.0625em; font-weight: normal; color: black">
                <span *ngIf="!!todayInfo">
                  {{ todayInfo?.title }}</span>
                <span *ngIf="!!todayInfo" [innerHTML]="todayInfo?.content | mySafeHtml"></span>
                <span *ngIf="!todayInfo" [innerHTML]="todayInfo">Il y' a pas d'info aujourd'hui</span>
              </p>
            </div>
          </div>
          <div class="card mb-1">
            <div class="card-header">
              <span class="card-title"
                style="font-size: 1.6em; font-weight: bold; color: black"><b>Entraînement</b></span>
            </div>
            <div class="card-body">
              <p class="card-text" style="font-size: 1.375em; font-weight: normal; color: black">
                Lancer un entraînement aléatoire
              </p>
              <button style="font-size: 1.75em;font-weight:bold;" pButton type="button" class="blue-button" label="Jouer" (click)="goToEntertainment()"
                ></button>
            </div>
          </div>
          <div class="card mb-1">
            <div class="card-header">
              <span class="card-title" style="font-size: 1.625em; font-weight: bold; color: black">Mes trophées</span>
              <span style="
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                ">
                <span class="mr-1" style="font-size: 1.375em; color: black; line-height: 1.375em">
                  {{ userState?.flames }}
                </span>
                <img *ngIf="!userState?.isFrozen" src="./../../../assets/images/icons/fire.svg" alt="fire-icon"
                  width="21px" height="33" />
                <img *ngIf="!!userState?.isFrozen" src="./../../../assets/images/icons/fire-blue.svg" alt="fire-icon"
                  width="21px" height="33" />
              </span>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <a style="
                    text-decoration: underline;
                    color: #2b2828;
                    font-size: 1em;
                  ">
                  Liste de trophées</a>
              </div>
              <div style="
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                ">
                <span *ngFor="let trophe of homeInformations.trophies.activeTrophe" class="mr-2 mb-1"><img
                    src="./../../../assets/images/icons/trophe-active.svg" alt="" width="45px" /></span>
                <span *ngFor="
                    let trophe of homeInformations.trophies.notActiveTrophe
                  " class="mr-2 mb-1"><img src="./../../../assets/images/icons/trophe-not-active.svg" alt=""
                    width="45px" /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>