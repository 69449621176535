import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "./../../../../../assets/images/icons/menu-icons/home.svg",
    iconType: "custom",
    url: "home",
    role:['USER']
  },
  {
    id: "rule",
    title: "rule",
    translate: "MENU.RULE",
    type: "item",
    icon: "./../../../../../assets/images/icons/menu-icons/rule.svg",
    iconType: "custom",
    url: "rule",
    role:['USER']
  },
  // {
  //   id: "GAME",
  //   title: "GAME",
  //   translate: "MENU.GAME",
  //   type: "item",
  //   icon: "./../../../../../assets/images/icons/menu-icons/game.svg",
  //   iconType: "custom",
  //   url: "game",
  //   role:['USER']
  // },
  {
    id: "CHAT",
    title: "CHAT",
    translate: "MENU.CHAT",
    type: "item",
    icon: "./../../../../../assets/images/icons/menu-icons/chat.svg",
    iconType: "custom",
    url: "chat",
    role:['USER']
  },
];
