import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';
import { CoreConfigService } from "@core/services/config.service";
import { countriesDetail } from "app/shared/country";
import { AuthenticationService } from 'app/auth/service';
import { NotificationService } from "app/services/toast.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PasswordValidator } from "app/shared/shared-functions";
import {
  genderOptions,
  imageOptions,
  reasonOptions,
  registerCarousel,
  dayOptionsList,
  monthOptionsList,
  yearOptionsList
} from "./register";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  // Public
  public emailVar;
  public coreConfig: any;
  public registerForm: FormGroup;
  public submitted = false;
  files: File[] = [];
  public messageTitle = ''
  public messageDescription = ''

  separateDialCode = false;
  //  SearchCountryField = SearchCountryField;
  //  CountryISO = CountryISO;
  //  PhoneNumberFormat = PhoneNumberFormat;
  //  preferredCountries: CountryISO[] = [CountryISO.Tunisia, CountryISO.France];

  phoneOptions: any[] = countriesDetail;
  dayOptions = dayOptionsList;
  monthOptions = monthOptionsList;
  yearOptions = yearOptionsList;
  genreOptions = genderOptions;
  reasonOptions = reasonOptions;
  imageOptions = imageOptions;
  formValue: any[] = registerCarousel;
  responsiveOptions: any[] | undefined;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   *
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private AuthenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private sanitizer: DomSanitizer
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }
  /**
   * On init
   */
   ngAfterViewInit() : void {
    const left = document.querySelector(".pi-chevron-left");
    const right = document.querySelector(".pi-chevron-right");
    if (left.classList.contains("pi-chevron-left")) {
      left.classList.remove("pi-chevron-left");
      left.classList.add("pi-arrow-left");
    }
    if (right.classList.contains("pi-chevron-right")) {
      right.classList.remove("pi-chevron-right");
      right.classList.add("pi-arrow-right");
    }
  }
  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone_dial_code: [
        {
          name: "France",
          dial_code: "+33",
          code: "FR",
        },
        Validators.required,
      ],
      phone: [null],
      day: [null],
      month: [null],
      year: [null],
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidator(8, true, true, true, true),
        ],
      ],
      confirmPassword: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidator(8, true, true, true, true),
        ],
      ],
      gender: [null, Validators.required],
      // birthdate: [null, Validators.required],
      reason: [null, Validators.required],
      image: [null],
    });

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = false;
    var year = this.registerForm.value.year;
    var month = this.registerForm.value.month; // Months are zero-indexed in JavaScript
    var day = this.registerForm.value.day;
    let birthdate = new Date(year, month, day);
    month = birthdate.getUTCMonth() + 1; // months from 1-12
    day = birthdate.getUTCDate();
    year = birthdate.getUTCFullYear();

    const newDate = year + "-" + month + "-" + day;



    // this.registerForm.value.reason = this.registerForm.value.reason.title;

    this.registerForm.value.birthdate = newDate
    const formData: FormData = new FormData();
    let file;
    if (this.registerForm.valid) {
      // let birthdate = this.registerForm.value.birthdate;
      formData.append('isTrial', new Boolean(true).toString())
      formData.append("email", this.registerForm.value.email);
      formData.append("password", this.registerForm.value.password);
      formData.append("firstName", this.registerForm.value.firstName);
      formData.append("lastName", this.registerForm.value.lastName);
      // formData.append("coach", this.registerForm.value.coach.id);
      formData.append(
        "phone",
        this.registerForm.value.phone_dial_code.dial_code +
        this.registerForm.value.phone
      );
      formData.append("gender", this.registerForm.value.gender);
      formData.append(
        "birthdate",
        `${year}-${month < 10 ? "0" + month : month
        }-${day < 10 ? "0" + day : day}`
      );
      formData.append("reason", this.registerForm.value.reason);
      if (this.files.length > 0) {
        formData.append("picture", this.files[0]);
        this.createUser(formData);
      }
      if (this.registerForm.value.image) {
        const filePath = this.registerForm.value.image.icon_png;

        fetch(filePath)
          .then((response) => response.blob())
          .then((blob) => {
            file = new File([blob], this.registerForm.value.image.name, {
              type: "image/png",
            });
            formData.append("picture", file);
          })
          .then(() => {
            this.createUser(formData)
          })
          .catch((error) => {
            this.submitted = false
            console.error("Error fetching file:", error);
          });
      }
      this.submitted = true;
      this.messageTitle = 'Merci pour tes réponses !'
      this.messageDescription = 'Commence ta formation avec un petit test de niveau qui te permettra de personnaliser ton parcours.'
      setTimeout(() => {
        this._router.navigate(["/login"]);
      }, 1500);
    } else {
      this.submitted = true;
      this.notificationService.showWarning(
        "Merci de vérifier votre formulaire",
        ""
      );
      this.messageTitle = 'Erreur'
      this.messageDescription = 'Merci de vérifier votre formulaire'

    }
  }
  createUser(formData) {
    this.AuthenticationService.addUser(formData).subscribe(
      (result) => {
        this.submitted = true;
        this.messageTitle = 'Merci pour tes réponses !'
        this.messageDescription = 'Commence ta formation avec un petit test de niveau qui te permettra de personnaliser ton parcours.'
        this.notificationService.showSuccess(
          "Commence ta formation avec un petit test de niveau qui te permettra de personnaliser ton parcours.",
          "Merci pour tes réponses !"
        );

      },
      (error) => {
        this.submitted = false;
        this.messageTitle = 'Erreur'
        this.messageDescription = 'Merci de vérifier votre formulaire'
      }
    );
  }
  pageChange(info: any): void {
    if (info.page == this.formValue.length - 1) {
      this.onSubmit();
    }
  }
  // changePreferredCountries() {
  // 	this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  // }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  /**
   * adds new file in uploaded files
   * @param event event
   */
  onSelect(event: any) {
    this.files.push(event.addedFiles[0]);
  }

  /**
   * removes file from uploaded files
   * @param event event
   */
  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  /**
   * Formats the size
   */
  getSize(f: File) {
    const bytes = f.size;
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  resetImage(info) {
    if (info.value.isX) this.registerForm.get("image")?.setValue(null);
  }

  /**
   * returns preview url of file
   * @param f file
   */
  getPreviewUrl(f: File) {
    return this.sanitizer.bypassSecurityTrustStyle(
      "url(" + URL.createObjectURL(f) + ")"
    );
  }

  /**
   * stops event propogation
   */
  onClick(event: any): void {
    event.stopPropagation();
  }
}
