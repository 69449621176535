import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AnecdoteService } from "app/services/anecdote.service";
import { GlobalService } from "app/services/global.service";
import { SessionService } from "app/services/session.service";
import { CoreConfigService } from "@core/services/config.service";
import { el } from "date-fns/locale";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  userState: any;
  levelImg: any;
  constructor(
    private router: Router,
    private anecdoteService: AnecdoteService,
    private globalService: GlobalService,
    private sessionService: SessionService, private _coreConfigService: CoreConfigService
  ) { }

  homeInformations: any;
  todayInfo: any;
  open: any;
  available: any;
  hasAttempt: any;
  lastSession: any;
  public contentHeader: object;

  ngOnInit() {

    this.homeInformations = {
      sessionCompleted: [
        {
          sessionNumber: 1,
          date: new Date(),
          score: 80.877,
        },
        {
          sessionNumber: 2,
          date: new Date(),
          score: 37.3,
        },
      ],
      trophies: {
        activeTrophe: [],
        notActiveTrophe: [{}, {}, {}, {}],
      },
    };
    this.getRandomAnecdote();
    this.getState();
  }
  isOpen(data) {
    console.log(data)
  }

  getState() {
    this.globalService.getUserState().subscribe((data: any) => {
      this.userState = data;
      this.available = !this.userState.isDisabled
      this.hasAttempt = this.userState.hasAttempt
      this.lastSession = this.userState.sessionId
      // this.isOpen(data);
      this.isTrial(data);
      this.userState.sessionsHistory = this.userState?.sessionsHistory?.map(
        (element) => {
          return {
            ...element,
            score: Math.round(element.score * 100) / 100,
          };
        }
      );
      this.levelImg = `./../../.././../assets/images/svg/navbar-stepper/level-${data.level}.svg`;
    });
  }
  getRandomAnecdote() {
    this.anecdoteService.getrandomAnecdote().subscribe((data) => {
      this.todayInfo = data;
    });
  }
  goToLessonPresentation() {
    this.router.navigate(["/lessons/lesson-presentation"]);
  }
  goToEntertainment() {
    this.router.navigate(["/lessons/lesson-training"]);
  }
  resetSession(id) {
    this.sessionService
      .resetSession({
        id: id
      })
      .subscribe({
        next: (data: any) => {
          this.getState();
          this.router.navigate(['/lessons/lesson-presentation', { session: id }]);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }
  isTrial(data) {
    if (data.isTrial == true) {
      let days = this.daysBetween(data.date)
      if (days >= 7)
        this.open = false
    } else
      this.open = true
  }
  goToNextStep() {
    if (this.userState.type == "TEST") {
      this.router.navigate(["/level-test/start"]);
    }
    if (this.userState.type == "SESSION") {
      this.router.navigate(["/lessons/lesson-presentation"]);
    }
  }
  daysBetween(dateString) {
    const today = new Date();
    const targetDate = new Date(dateString);
    if (isNaN(targetDate.getTime())) {
      return 10;
    }
    const timeDifference = today.getTime() - targetDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }
}
