import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
const basePath = environment.basePath;

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(private http: HttpClient) { }

  getCurrentSession(id=null) {
    let param = '/'
    if (id)
      param = '/' + encodeURIComponent(JSON.stringify(id))
    return this.http.get(`${basePath}/sessions/current${param}`)
  }
  getResult(id=null) {
    let param = '/'
    if (id)
      param = '/' + encodeURIComponent(JSON.stringify(id))
    return this.http.get(`${basePath}/sessions/result${param}`)
  }
  validateAnswer(data) {
    return this.http.get(`${basePath}/sessions/validate-question/${encodeURIComponent(JSON.stringify(data))}`, {})
  }
  getAudio(data) {
    return this.http.get(`${basePath}${data}`, {})
  }
  submitSession(data) {
    return this.http.post(`${basePath}/sessions/submit`, data)
  }
  resetSession(data) {
    return this.http.post(`${basePath}/sessions/reset`, data)
  }
}
