<!-- <div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
      <a class="brand-logo" href="javascript:void(0);">
        <h2 class="brand-text text-primary ml-1"></h2></a
      >

    </div>
  </div> -->

<div class="row">
  <div class="col-8">
    <a class="navbar-brand" [routerLink]="['/']">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" style="height: 50px;margin: 10px;" height="70" />
    </a>
  </div>
  <div class="col-4 pt-2 pr-3" style="text-align: right">
    <a routerLink="/register">
      <button pButton type="button" label="Connexion" class="p-button-text"></button></a>
  </div>
  <div class="col-lg-12 mt-5 pl-5 pr-5" style="z-index: 200">
    <p-carousel (onPage)="pageChange($event)" [value]="formValue" [numVisible]="1" [numScroll]="1"
      [responsiveOptions]="responsiveOptions" [page]="0">
      <ng-template let-formValue pTemplate="item">
        <form [formGroup]="registerForm" class="source-item" style="width: 100%">
          <div class="container-fluid callback">
            <div class="container pt-3 pb-2">
              <div class="row" style="position: relative;">
                <div [ngClass]="{
                    'col-6': formValue.id == 13,
                    'col-12': formValue.id !== 13
                  }" class="pb-1">
                  <h1>
                    <b>
                      <span [innerHTML]="formValue?.title"></span>
                    </b>
                  </h1>
                  <div *ngIf="formValue?.subtitle && formValue?.subtitle !== ''">
                    <h2>
                      <span [innerHTML]="formValue?.subtitle"></span>
                    </h2>
                  </div>
                </div>
                <div class="" *ngIf="formValue.id == 14">
                  <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [disableClick]="
                    files.length > 0 || registerForm.get('image').value !== null
                  " style="
                    height: auto !important;
                    min-width: 224px;
                    border: none;
                    position: absolute;
                    right: 0;
                  " accept="image/jpeg,image/jpg,image/png">
                    <ngx-dropzone-label style="margin: 10px 10px">
                      <i class="h1 text-muted dripicons-cloud-upload"></i>
                      <img style="
                        max-width: 262px;
                        width: 262px;
                        min-width: 262px;
                        float: right;
                      " src="./../../../../assets/images/icons/svg-images/register-page-13-1.svg" alt="image-icon" />
                    </ngx-dropzone-label>
                    <ng-container *ngFor="let f of files">
                      <ngx-dropzone-preview [removable]="true" (removed)="onRemove(f)" (click)="onClick($event)"
                        [style.background-image]="getPreviewUrl(f)" [style.background-size]="'cover'"
                        *ngIf="f.type.includes('image')" style="border: 1px solid" [disab]>
                      </ngx-dropzone-preview>
                    </ng-container>
                  </ngx-dropzone>
                </div>
                <div class="col-12 center-with-padding" style="top: 170px" *ngIf="formValue?.id == 6">
                  <p-dropdown [options]="genreOptions" optionLabel="name" [autoDisplayFirst]="false" optionValue="value"
                    formControlName="gender" id="kind" placeholder="Mon genre">
                  </p-dropdown>
                </div>
                <div class="col-12 center-with-padding" style="top: 170px" *ngIf="formValue?.id == 7">
                  <input type="text" pInputText [(ngModel)]="lastName" formControlName="lastName" id="title"
                    placeholder="Prénom" />
                </div>
                <div class="col-12 center-with-padding" style="top: 170px" *ngIf="formValue?.id == 8">
                  <input type="text" pInputText [(ngModel)]="firstName" formControlName="firstName" id="title"
                    placeholder="Nom" />
                </div>
                <div class="col-12 row center-with-padding" style="top: 170px" *ngIf="formValue?.id == 9">
                  <div class="col-4">
                    <!-- <p-dropdown [options]="genreOptions" optionLabel="name" [autoDisplayFirst]="false"
                      optionValue="value" formControlName="gender" id="kind" placeholder="Mon genre">
                    </p-dropdown> -->
                    <p-dropdown [options]="dayOptions" optionLabel="name" [autoDisplayFirst]="false" optionValue="value"
                      formControlName="day" placeholder="jj">
                    </p-dropdown>
                  </div>
                  <div class="col-4">
                    <p-dropdown [options]="monthOptions" optionLabel="name" [autoDisplayFirst]="false"
                      optionValue="value" formControlName="month" placeholder="MM">
                    </p-dropdown>
                  </div>
                  <div class="col-4">
                    <p-dropdown [options]="yearOptions" optionLabel="name" [autoDisplayFirst]="false"
                      optionValue="value" formControlName="year" placeholder="AAAA">
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-12 row reason-class" *ngIf="formValue?.id == 10">
                  <p-selectButton [options]="reasonOptions" optionValue="title" formControlName="reason"
                    optionLabel="icon">
                    <ng-template let-element class="col-lg-6 col-md-6 col-sm-6">
                      <div class="row">
                        <div class="col-8" style="text-align: left; width: 280px">
                          <h4>{{ element.title }}</h4>
                          <h6>{{ element.subtitle }}</h6>
                        </div>
                        <div class="col-4">
                          <img class="img-fluid" [src]="element?.icon" style="width: 100px" alt="icon" />
                        </div>
                      </div>
                    </ng-template>
                  </p-selectButton>
                </div>
                <div class="col-12 row center-with-padding" style="top: 170px" *ngIf="formValue?.id == 11">
                  <div class="col-12 mb-2">
                    <input type="password" pInputText formControlName="password" id="password"
                      placeholder="Mot de passe" />
                  </div>
                  <div class="col-12">
                    <input type="password" pInputText formControlName="confirmPassword" id="confirmPassword"
                      placeholder="Confirme ton mot de passe" />
                  </div>
                </div>
                <div class="col-12 center-with-padding" *ngIf="formValue?.id == 12" style="top: 195px; display: flex">
                  <p-dropdown [options]="phoneOptions" formControlName="phone_dial_code" optionLabel="dial_code"
                    [filter]="true" filterBy="name" [showClear]="true" placeholder="Tél">
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        <img src="assets/images/flagspack/{{
                            country.code.toLowerCase()
                          }}.svg" alt="{{ country.name.toLowerCase() }}" [class]="
                            'flag flag-' +
                            country.code.toLowerCase() +
                            ' imgFlag'
                          " />
                        {{ country.dial_code }}
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <input type="text" pInputText formControlName="phone" [(ngModel)]="phone" id="phone"
                    placeholder="0.00.00.00.00" />
                  <!-- <div class="col-9">
                    
                  </div> -->
                </div>
                <div class="col-12 center-with-padding" style="top: 170px" *ngIf="formValue?.id == 13">
                  <input type="text" pInputText formControlName="email" [(ngModel)]="email" id="email"
                    placeholder="Email" />
                </div>
                <div class="col-12 row" *ngIf="formValue?.id == 14" style="text-align: center">
                  <div class="col-12">
                    <p-selectButton [options]="imageOptions" optionValue="details" formControlName="image"
                      [disabled]="files.length > 0" optionLabel="icon" (onChange)="resetImage($event)">
                      <ng-template let-element>
                        <img *ngIf="element.icon !== 'X'" class="img-fluid" [src]="element?.icon" style="width: 110px"
                          alt="icon" />
                        <h1 *ngIf="element.icon === 'X'">{{ element.icon }}</h1>
                      </ng-template>
                    </p-selectButton>
                  </div>
                  <div class="col-12" style="text-align: center">
                    <h6>… ou choisis un avatar.</h6>
                  </div>
                </div>
                <div class="col-12 center-with-padding row" style="top: 170px" *ngIf="formValue?.id == 15">
                  <div class="col-6 mb-2">
                    <input type="text" [(ngModel)]="firstName" pInputText disabled="true" formControlName="firstName"
                      id="prenom" placeholder="Prénom" />
                  </div>
                  <div class="col-6 mb-2">
                    <input type="text" [(ngModel)]="lastName" pInputText disabled="true" formControlName="lastName"
                      id="nom" placeholder="Nom" />
                  </div>
                  <div class="col-12 mb-2">
                    <input type="text" [(ngModel)]="phone" pInputText disabled="true" formControlName="phone" id="phone"
                      placeholder="(+33)0.00.00.00.00" />
                  </div>
                  <div class="col-12 mb-2">
                    <input type="text" [(ngModel)]="email" pInputText disabled="true" formControlName="email" id="email"
                      placeholder="email@site.org" />
                  </div>
                </div>
                <div class="col-12" style="height: 400px" *ngIf="formValue?.icon && formValue?.icon !== ''">
                  <img class="img-fluid" [src]="formValue?.icon" [alt]="'page' + formValue.id"
                    style="float: right; z-index: 100; width: 200px; margin-top: 9em;" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </p-carousel>
  </div>
  <div class="col-12 footer-svg" style="z-index: 1">
    <img src="assets\images\icons\background-wave.svg" alt="" />
  </div>
</div>