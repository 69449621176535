import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthenticationService } from "app/auth/service";
import { NotificationService } from "app/services/toast.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].indexOf(err.status) !== -1 && !request.url.split('/').includes('login-admin')) {
            this._authenticationService.logout()
            this.notificationService.showError('vous n\'êtes pas autorisé à y accéder.','Désolé');
        }
        if(err.status === 409){
          this.router.navigate(['/']);
        }
        // throwError
        const error = err;
        return throwError(error);
      })
    );
  }
}
