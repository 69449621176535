import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'qcu-component',
  templateUrl: './qcu-component.component.html',
  styleUrls: ['./qcu-component.component.scss']
})
export class QcuComponentComponent implements OnInit {

  @Input() item: any;
  constructor() { }

  ngOnInit(): void {
  }

}
