<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" [routerLink]="['/']">
      <img
        src="{{ coreConfig.app.appLogoImage }}"
        alt="brand-logo"
        style="height: 50px;margin: 10px;"
        height="70"
      />
      <h2 class="brand-text text-primary ml-1"></h2>
    </a>
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/login-v2-dark.svg'
              : 'assets/images/pages/login-v2.svg'
          "
          alt="Login V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Login-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">
          Bienvenue dans Orthographe<span style="color: #6194cd">Plus</span>! 👋
        </h2>
        <p class="card-text mb-2">
          Veuillez vous connecter à votre compte et commencer l'aventure
        </p>

        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
          <div class="alert-body">
            <p>{{ error }}</p>
          </div>
        </ngb-alert>

        <form
          class="auth-login-form mt-2"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label class="form-label" for="login-email">Email</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              placeholder="admin@demo.com"
              aria-describedby="login-email"
              autofocus=""
              tabindex="1"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email requis</div>
              <div *ngIf="f.email.errors.email">
                L'email doit être une adresse e-mail valide
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Mot de passe</label
              ><a routerLink="/forgot-password"
                ><small>Mot de passe oublié?</small></a
              >
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                [ngClass]="{
                  'is-invalid error': submitted && f.password.errors
                }"
                placeholder="············"
                aria-describedby="login-password"
                tabindex="2"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <button
            type="submit"
            [disabled]="loading"
            class="btn btn-primary btn-block"
            tabindex="4"
            rippleEffect
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Connexion
          </button>
          <div class="d-flex justify-content-between">
            <label for="login-password">Nouveau sur Orthographe-Plus ?</label
            ><a routerLink="/register"
              ><small>Inscrivez-vous</small></a
            >
          </div>
        </form>
      </div>
    </div>
    <!-- /Login-->
  </div>
</div>
