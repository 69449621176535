import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MenubarModule } from "primeng/menubar";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { ProgressBarModule } from "primeng/progressbar";
import { ToastModule } from "primeng/toast";
import { CarouselModule } from "primeng/carousel";
import { SelectButtonModule } from "primeng/selectbutton";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { RadioButtonModule } from "primeng/radiobutton";
import { CheckboxModule } from "primeng/checkbox";
import { FileUploadModule } from "primeng/fileupload";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ConfirmationService, MessageService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { StepsModule } from "primeng/steps";
import { TabViewModule } from "primeng/tabview";
import { TabMenuModule } from "primeng/tabmenu";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { StyleClassModule } from "primeng/styleclass";
import {KnobModule} from 'primeng/knob';

import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgbNavModule, NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { CoreDirectivesModule } from "@core/directives/directives";
import { CoreSidebarModule } from "@core/components";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { InfoComponent } from "./info/info.component";
import { SmsComponentComponent } from "./question-components/sms-component/sms-component.component";
import { CorrectionComponentComponent } from "./question-components/correction-component/correction-component.component";
import { QcuComponentComponent } from "./question-components/qcu-component/qcu-component.component";
import { QcmComponentComponent } from "./question-components/qcm-component/qcm-component.component";
import { TrueFalseComponentComponent } from "./question-components/true-false-component/true-false-component.component";
import { InfoCardComponent } from "./info-card/info-card.component";
import { DynamicInputWidthDirective } from "./directive/dynamic-input-width.directive";
import { TooltipModule } from "primeng/tooltip";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { NgxDropzoneModule } from 'ngx-dropzone';
@NgModule({
  declarations: [
    InfoComponent,
    SmsComponentComponent,
    CorrectionComponentComponent,
    QcuComponentComponent,
    QcmComponentComponent,
    TrueFalseComponentComponent,
    InfoCardComponent,
    DynamicInputWidthDirective,
  ],
  imports: [
    CommonModule,
    MenubarModule,
    AvatarModule,
    ButtonModule,
    ProgressBarModule,
    ToastModule,
    CarouselModule,
    SelectButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    InputMaskModule,
    InputNumberModule,
    RadioButtonModule,
    CheckboxModule,
    FileUploadModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    StepsModule,
    TabViewModule,
    TabMenuModule,
    ScrollPanelModule,
    StyleClassModule,
  ],
  exports: [
    MenubarModule,
    AvatarModule,
    ButtonModule,
    ProgressBarModule,
    ToastModule,
    CarouselModule,
    SelectButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    InputMaskModule,
    InputNumberModule,
    RadioButtonModule,
    CheckboxModule,
    FileUploadModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    StepsModule,
    TabViewModule,
    TabMenuModule,
    ScrollPanelModule,
    StyleClassModule,
    TooltipModule,
    DragDropModule,
    KnobModule,
    NgbNavModule,
    NgbModule,
    NgSelectModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule,
    NgxDatatableModule,
    Ng2FlatpickrModule,
    InfoComponent,
    SmsComponentComponent,
    CorrectionComponentComponent,
    QcuComponentComponent,
    QcmComponentComponent,
    TrueFalseComponentComponent,
    DynamicInputWidthDirective,
    NgxDropzoneModule,
  ],
  providers: [MessageService, ConfirmationService],
})
export class SharedModule {}
